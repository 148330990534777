import * as React from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "../../../../hooks/useAppSelector"
import LocalizationConstant from "../../../../i18n/LocalizationConstant"

export function ResultTitle() {
  const { currentPeepo } = useAppSelector(state => state.guid)
  const { t } = useTranslation()

  if (currentPeepo === undefined) {
    return <></>
  }

  const { selectedFriend } = currentPeepo
  if (selectedFriend === undefined) {
    return (
      <>
        <h2 className="text-center">
          {t(LocalizationConstant.UNFORTUNATELY_NO_PARTICIPANTS)}
        </h2>
      </>
    )
  }

  return (
    <>
      <p className="text-primary">
        {t(LocalizationConstant.THE_PEEPO_IS_FOR)} {selectedFriend}
      </p>
      <h2 className="text-center">
        {selectedFriend} {t(LocalizationConstant.MUST_ORGANIZE_THE_FOLLOWING)}
      </h2>
    </>
  )
}
