import * as React from 'react';
import { useTranslation } from 'react-i18next';
import LocalizationConstant from '../../../../i18n/LocalizationConstant';
import { EventInfo } from '../../../shared/EventInfo';
import { ParticipantList } from '../../../shared/ParticipantList';
import { LinkCopier } from '../../../utils/LinkCopier';
import { ResultTitle } from './ResultTitle';
// import { PeepoPremiumCard } from '../../participant-page/components/PeepoPremiumCard';

export function FinalInformationContent () {
  const { t } = useTranslation()

  return (
    <>
      <ResultTitle/>
      <EventInfo/>
      <ParticipantList showFinishedVersion={true}/>
      <p className='text-center'>{t(LocalizationConstant.LET_THE_PARTICIPANTS_KNOW)}</p>
      <LinkCopier link={window.location.href}/>
      {/* <PeepoPremiumCard/> */}
    </>
  );
}
