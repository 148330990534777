import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons"
import { faNewspaper } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { colorConfig } from "../../config/colorConfig"
import { useAppSelector } from "../../hooks/useAppSelector"

export function EventInfo() {
  const { currentPeepo } = useAppSelector(state => state.guid)

  if (currentPeepo ===  undefined) {
    return <></>
  }

  const { title, location, note } = currentPeepo
  return (
    <>
      <div className="d-flex flex-row justify-content-between border-secondary border-5 rounded p-2 m-2">
        <StaticImage
          height={100}
          width={100}
          className="align-self-center rounded"
          src="../../images/placeholder.png"
          alt={"Event image."}
        />
        <div className="d-flex flex-column justify-content-center align-items-start">
          <div>
            <p className="fs-5 fw-bold">{title}</p>
          </div>
          {location && (
            <div className="d-flex flex-row align-items-center">
              <FontAwesomeIcon
                color={colorConfig.peepoGray}
                icon={faMapMarkedAlt}
                size="1x"
              />
              <span className="m-2">{location}</span>
            </div>
          )}
          {note && (
            <div className="d-flex flex-row align-items-center">
              <FontAwesomeIcon
                color={colorConfig.peepoGray}
                icon={faNewspaper}
                size="1x"
              />
              <span className="m-2">{note}</span>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
