import * as React from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "../../hooks/useAppSelector"
import LocalizationConstant from "../../i18n/LocalizationConstant"

export interface IParticipantListProps {
  showFinishedVersion: boolean
}

export function ParticipantList(props: IParticipantListProps) {
  const { showFinishedVersion } = props
  const { t } = useTranslation()
  const { currentPeepo } = useAppSelector(state => state.guid)
  console.log(currentPeepo);

  if (currentPeepo === undefined || !currentPeepo?.peepoFriends) {
    return <></>
  }

  const peepoFriends = currentPeepo?.peepoFriends
  
  const getFriendListText = () => {
    // first one text
    if (peepoFriends.length === 0) {
      return t(LocalizationConstant.CONGRATS_YOU_ARE_THE_FIRST_TO_PARTICIPATE)
    }
    // finished text
    if (showFinishedVersion) {
      return t(LocalizationConstant.FOLLOWING_PEOPLE_PARTICIPATED)
    }
    // so far text
    return t(LocalizationConstant.SO_FAR_FOLLOWING_PEOPLE_HAVE_PARTICIPATED)
  }

  const text = getFriendListText()
  return (
    <>
      <p className="text-center">{text}&nbsp;
        {showFinishedVersion && <b>{currentPeepo.name}</b>}
        {showFinishedVersion && <>,&nbsp;</>}
        {peepoFriends.length > 0 && peepoFriends
          ?.map((peepoFriend, index) => {
            // last one - and with period.
            if (index === peepoFriends.length - 1) {
              return <>{t(LocalizationConstant.AND)} <b key={`friend-${index}`}>{peepoFriend.name}</b>.</>
            }
            // otherwise, comma and space
            return <><b key={`friend-${index}`}>{peepoFriend.name}</b>,&nbsp;</>
          })}
      </p>
    </>
  )
}
