import { useEffect } from "react"
import PageType from "../enums/PageType";
import { useGetPeepoByGuidQuery } from "../services/peepo";
import { resetPeepo, setGuid, setPeepo } from "../store/guidSlice";
import { redirectToHomepage } from "../utils/redirectToHomepage";
import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";

const v4 = new RegExp(
  /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
)

// on mount, attempt to parse out the page's GUID. If not found, redirect to homepage
export const usePageGuid = (pageType: PageType) => {
  const { currentGuid } = useAppSelector(state => state.guid)
  // skip when the GUID is empty
  const { data, error } = useGetPeepoByGuidQuery(currentGuid , { skip: currentGuid === '' })
  const dispatch = useAppDispatch()

  useEffect(() => {
    const parts = window.location.href.split("/")
    // if we have exact match to the regex we expect, set it as the GUID
    if (parts.length === 5 && parts[4].match(v4)) {
      dispatch(setGuid(parts[4]))
    } else {
      // invalid GUID - redirect to home
      redirectToHomepage("invalid-id")
    }
    // Clear the value of the GUID on cleanup
    return () => {
      dispatch(setGuid(''))
      dispatch(resetPeepo());
    }
  }, [])

  // when the current data changes, set the peepo data
  useEffect(() => {
    if (pageType === PageType.RESULT && data && data.generated === false) {
      redirectToHomepage("peepo-not-yet-available")
    }
    else if (data && data.guid) {
      dispatch(setPeepo(data));
    }
  },[data])

  // if error, redirect to homepage
  useEffect(() => {
    if (error != undefined) {
      redirectToHomepage("unknown-error")
    }
  },[error])
}
