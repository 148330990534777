import * as React from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "../../../hooks/useAppSelector"
import { usePageGuid } from "../../../hooks/usePageGuid"
import LocalizationConstant from "../../../i18n/LocalizationConstant"
import { WhitePaddedWrapper } from "../../utils/WhitePaddedWrapper"
import { Title } from "../../shared/Title"
import { FinalInformationContent } from "./components/FinalInformationContent"
import PageType from "../../../enums/PageType"

export function ResultPage() {
  const { t } = useTranslation()
  const {currentPeepo} = useAppSelector(state => state.guid)
  usePageGuid(PageType.RESULT)

  if (currentPeepo === undefined) {
    return <></>
  }

  return (
    <>
      <Title
        title={t(LocalizationConstant.PARTICIPATION_TITLE)}
        subtitles={[
          t(LocalizationConstant.HOME_DESCRIPTION_ONE),
          t(LocalizationConstant.HOME_DESCRIPTION_TWO),
        ]}
      />
      <WhitePaddedWrapper>
        <FinalInformationContent />
      </WhitePaddedWrapper>
    </>
  )
}
