import { PageProps } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import Layout from "../components/layout/Layout"
import { ResultPage } from "../components/pages/result-page/ResultPage"
import SEO from "../components/utils/SEO"
import LocalizationConstant from "../i18n/LocalizationConstant"

export default function PeepoResult(props: PageProps) {
  const { t } = useTranslation()
  return (
    <Layout pageProps={props}>
      <SEO title={t(LocalizationConstant.RESULT_PAGE_TITLE)}/>
      <ResultPage />
    </Layout>
  )
}
